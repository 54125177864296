var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { registerSchool, registerSchoolLeader } from '../../services/SchoolAPI/SchoolAPI';
const initialState = {
    loading: false,
    error: false,
    eventUsers: [],
    eventLogParticipants: { grades: [], participants: [] },
    schoolRegistrationSucceed: false,
    eventEditData: null,
};
export const register = createAsyncThunk('/school/register', (data) => __awaiter(void 0, void 0, void 0, function* () {
    return yield registerSchool(data);
}));
export const registerLeader = createAsyncThunk('/school/register/leader', (data) => __awaiter(void 0, void 0, void 0, function* () {
    return yield registerSchoolLeader(data);
}));
export const SchoolSlice = createSlice({
    name: 'school',
    initialState,
    reducers: {
        setEventUsers: (state, action) => {
            state.eventUsers = action.payload;
        },
        setEventLogParticipants: (state, action) => {
            state.eventLogParticipants = action.payload;
        },
        clear: () => initialState,
        setSchoolRegistrationSucceed: (state, action) => {
            state.schoolRegistrationSucceed = action.payload;
        },
        setEventEditData: (state, action) => {
            state.eventEditData = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(register.pending, (state) => (Object.assign(Object.assign({}, state), { loading: true })));
        builder.addCase(register.fulfilled, (state, action) => {
            if (!!action.payload)
                return Object.assign(Object.assign({}, state), { loading: false, schoolRegistrationSucceed: true });
        });
        builder.addCase(register.rejected, (state) => (Object.assign(Object.assign({}, state), { error: true })));
        builder.addCase(registerLeader.pending, (state) => (Object.assign(Object.assign({}, state), { loading: true })));
        builder.addCase(registerLeader.fulfilled, (state, action) => {
            if (!!action.payload)
                return Object.assign(Object.assign({}, state), { loading: false });
        });
        builder.addCase(registerLeader.rejected, (state) => (Object.assign(Object.assign({}, state), { error: true })));
    },
});
export const { setEventUsers, setSchoolRegistrationSucceed, setEventLogParticipants, setEventEditData, clear } = SchoolSlice.actions;
export default SchoolSlice.reducer;
