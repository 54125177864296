import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    userDataFile: [],
    uploadFileError: [],
    isUploadLoading: false,
    isDownloading: false,
    sports: [],
    walkingSport: { name: '', conversionRate: 0 },
    forgotUserEmail: '',
};
export const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserDataFile: (state, action) => {
            state.userDataFile = action.payload;
        },
        setUpladFileError: (state, action) => {
            state.uploadFileError = action.payload;
        },
        setIsUploadLoading: (state, action) => {
            state.isUploadLoading = action.payload;
        },
        setIsDownloading: (state, action) => {
            state.isDownloading = action.payload;
        },
        setSports: (state, action) => {
            state.sports = action.payload;
        },
        setWalkingSport: (state, action) => {
            state.walkingSport = action.payload;
        },
        setForgotUserEmail: (state, action) => {
            state.forgotUserEmail = action.payload;
        },
        clearSelection: (state) => {
            state.uploadFileError = [];
            state.userDataFile = [];
        },
        clear: () => initialState,
    },
    extraReducers() { },
});
export const { setUserDataFile, setUpladFileError, clearSelection, setIsUploadLoading, setIsDownloading, setSports, setWalkingSport, setForgotUserEmail, clear, } = UserSlice.actions;
export default UserSlice.reducer;
